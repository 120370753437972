// src/components/Resource.js
import React, { useState, useEffect, useRef } from 'react';
import './Resource.css';

function Resource({ name, amount, max }) {
  const [displayAmount, setDisplayAmount] = useState(Math.floor(amount));
  const [prevAmount, setPrevAmount] = useState(Math.floor(amount));
  const [colorClass, setColorClass] = useState('');
  const timeoutRef = useRef(null);

  useEffect(() => {
    const currentAmount = Math.floor(amount);

    if (currentAmount < prevAmount) {
      // Wert hat sich verringert
      setColorClass('decrease');

      // Falls bereits ein Timeout läuft, löschen wir ihn
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Nach einer Sekunde die Farbe zurücksetzen
      timeoutRef.current = setTimeout(() => {
        setColorClass('');
        timeoutRef.current = null; // Referenz zurücksetzen
      }, 1000); // 1000 Millisekunden = 1 Sekunde
    }

    // Vorherigen Wert aktualisieren
    setPrevAmount(currentAmount);
    setDisplayAmount(currentAmount);
  }, [amount]);

  // Cleanup beim Unmounten der Komponente
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Überprüfen, ob die Ressource ihr Maximum erreicht hat
  const isMaxed = max !== null && displayAmount >= max;

  return (
    <div className={`resource-item ${colorClass} ${isMaxed ? 'maxed' : ''}`}>
      <strong>{name}:</strong> {displayAmount} {max !== null && ` / ${max}`}
    </div>
  );
}

export default Resource;
