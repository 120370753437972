// src/pages/Statistics.js
import React from 'react';
import { useOutletContext } from 'react-router-dom';

function Statistics() {
  const { productionPerMinute, storageCapacity } = useOutletContext();

  return (
    <div>
      <h2>Statistiken</h2>
      <p>Aktuelle Produktion der Rohstoffe pro Minute:</p>
      <ul>
        <li>Holz: {productionPerMinute.holz} pro Minute</li>
        <li>Stein: {productionPerMinute.stein} pro Minute</li>
        <li>Nahrung: {productionPerMinute.nahrung} pro Minute</li>
      </ul>

      <h3>Lagerkapazitäten</h3>
      <ul>
        <li>Holz: {storageCapacity.holz}</li>
        <li>Stein: {storageCapacity.stein}</li>
        <li>Nahrung: {storageCapacity.nahrung}</li>
      </ul>
    </div>
  );
}

export default Statistics;
