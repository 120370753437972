// src/components/ResourceList.js
import React from 'react';
import Resource from './Resource';
import './ResourceList.css';

function ResourceList({ resources, storageCapacity, showMax = false }) {
  return (
    <div className="resource-list">
      <Resource name="Holz" amount={resources.holz} max={showMax ? storageCapacity.holz : null} />
      <Resource name="Stein" amount={resources.stein} max={showMax ? storageCapacity.stein : null} />
      <Resource name="Nahrung" amount={resources.nahrung} max={showMax ? storageCapacity.nahrung : null} />
    </div>
  );
}

export default ResourceList;
