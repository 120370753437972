// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import Statistics from './pages/Statistics';
import Buildings from './pages/Buildings';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css'; // Stellen Sie sicher, dass die CSS-Datei importiert wird

function App() {
  const [userAddress, setUserAddress] = useState(null);
  const [notification, setNotification] = useState('');

  // Laden des gespeicherten userAddress aus localStorage beim Start
  useEffect(() => {
    const storedAddress = localStorage.getItem('userAddress');
    if (storedAddress) {
      setUserAddress(storedAddress);
    }
  }, []);

  const handleLogin = (address) => {
    console.log('handleLogin - address:', address);
    setUserAddress(address);
    localStorage.setItem('userAddress', address);
    setNotification('');
  };

  const handleAccountsChanged = (accounts) => {
    console.log('handleAccountsChanged - accounts:', accounts);
    if (accounts.length === 0) {
      // Benutzer hat sich abgemeldet oder MetaMask ist deaktiviert
      setUserAddress(null);
      localStorage.removeItem('userAddress');
      setNotification('Ihr Konto wurde abgemeldet.');
    } else {
      // Ein anderes Konto wurde ausgewählt
      setUserAddress(accounts[0]);
      localStorage.setItem('userAddress', accounts[0]);
      setNotification('Konto wurde gewechselt.');
    }
  };

  const handleChainChanged = (_chainId) => {
    console.log('handleChainChanged - chainId:', _chainId);
    // Optional: Überprüfen der neuen Chain-ID
    setUserAddress(null);
    localStorage.removeItem('userAddress');
    setNotification('Die Blockchain-Kette wurde geändert. Bitte loggen Sie sich erneut ein.');
  };

  useEffect(() => {
    if (window.ethereum) {
      // Hinzufügen der Event Listener
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      // Cleanup-Funktion zum Entfernen der Event Listener
      return () => {
        if (window.ethereum.removeListener) {
          window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
          window.ethereum.removeListener('chainChanged', handleChainChanged);
        }
      };
    }
  }, []);

  useEffect(() => {
    console.log('App - userAddress:', userAddress);
  }, [userAddress]);

  return (
    <Router>
      <div className="App">
        {notification && <div className="notification">{notification}</div>}
        <Routes>
          {/* Login-Route */}
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />

          {/* Geschützte Routen */}
          <Route
            path="/"
            element={
              <ProtectedRoute userAddress={userAddress}>
                <MainPage userAddress={userAddress} />
              </ProtectedRoute>
            }
          >
            <Route path="statistics" element={<Statistics />} />
            <Route path="buildings" element={<Buildings />} />
            <Route index element={<div>Willkommen zum Spiel!</div>} />
          </Route>

          {/* Fallback-Route */}
          <Route path="*" element={<Navigate to={userAddress ? "/" : "/login"} replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
