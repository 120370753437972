// src/pages/Buildings.js
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import './Buildings.css';

function Buildings() {
  const { resources, buildings, setBuildings, setResources } = useOutletContext();
  
  const [activeTab, setActiveTab] = useState('wirtschaft'); // Zustand für aktiven Tab

  // Basis-Ausbaukosten ab Level 2
  const baseUpgradeCosts = {
    holzfaeller: { holz: 50, stein: 30, nahrung: 10 },
    steinmetz: { holz: 40, stein: 50, nahrung: 20 },
    farm: { holz: 50, stein: 30, nahrung: 60 },
    lager: { holz: 100, stein: 80, nahrung: 50 }, // Basiskosten für Lager
  };

  // Kostensteigerungsfaktoren pro Level
  const upgradeCostFactors = {
    holzfaeller: 0.9,
    steinmetz: 0.8,
    farm: 0.7,
    lager: 0.7, // Faktor für Lager
  };

  const canAffordUpgrade = (building) => {
    const level = buildings[building].level;
    const cost = calculateUpgradeCost(building, level + 1);

    return (
      resources.holz >= cost.holz &&
      resources.stein >= cost.stein &&
      resources.nahrung >= cost.nahrung
    );
  };

  const calculateUpgradeCost = (building, targetLevel) => {
    const baseCost = baseUpgradeCosts[building];
    const factor = upgradeCostFactors[building];
    const levelDifference = targetLevel - 2; // Level 2 ist das Basislevel für Kosten

    const cost = {
      holz: Math.floor(baseCost.holz * Math.pow(factor, levelDifference)),
      stein: Math.floor(baseCost.stein * Math.pow(factor, levelDifference)),
      nahrung: Math.floor(baseCost.nahrung * Math.pow(factor, levelDifference)),
    };

    return cost;
  };

  const handleUpgrade = (building) => {
    const level = buildings[building].level;
    const targetLevel = level + 1;
    const cost = calculateUpgradeCost(building, targetLevel);

    if (canAffordUpgrade(building)) {
      // Ressourcen abziehen
      setResources((prevResources) => ({
        holz: prevResources.holz - cost.holz,
        stein: prevResources.stein - cost.stein,
        nahrung: prevResources.nahrung - cost.nahrung,
      }));

      // Gebäude-Level erhöhen
      setBuildings((prevBuildings) => ({
        ...prevBuildings,
        [building]: { level: targetLevel },
      }));
    }
  };

  // Liste der Gebäude nach Kategorie
  const wirtschaftGebaeude = ['holzfaeller', 'steinmetz', 'farm'];
  const zivileGebaeude = ['lager'];

  // Funktion zur Darstellung eines Gebäudes
  const renderBuilding = (building) => {
    return (
      <div className="building" key={building}>
        <h3>
          {capitalizeFirstLetter(building)} (Level {buildings[building].level})
        </h3>
        <p>Kosten für Level {buildings[building].level + 1}:</p>
        <ul>
          {Object.entries(calculateUpgradeCost(building, buildings[building].level + 1)).map(
            ([key, value]) => (
              <li key={key}>
                {capitalizeFirstLetter(key)}: {value}
              </li>
            )
          )}
        </ul>
        <button
          onClick={() => handleUpgrade(building)}
          disabled={!canAffordUpgrade(building)}
        >
          Ausbauen
        </button>
      </div>
    );
  };

  // Hilfsfunktion zur Großschreibung des ersten Buchstabens
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="buildings-container">
      <h2>Gebäude</h2>
      {/* Tabs */}
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'wirtschaft' ? 'active' : ''}`}
          onClick={() => setActiveTab('wirtschaft')}
        >
          Wirtschaft
        </button>
        <button
          className={`tab ${activeTab === 'zivile' ? 'active' : ''}`}
          onClick={() => setActiveTab('zivile')}
        >
          Zivile
        </button>
      </div>

      {/* Inhalt basierend auf aktivem Tab */}
      <div className="tab-content">
        {activeTab === 'wirtschaft' && (
          <div className="category">
            {wirtschaftGebaeude.map((building) => renderBuilding(building))}
          </div>
        )}
        {activeTab === 'zivile' && (
          <div className="category">
            {zivileGebaeude.map((building) => renderBuilding(building))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Buildings;
