// src/pages/MainPage.js
import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import ResourceList from '../components/ResourceList';
import './MainPage.css';

function MainPage({ userAddress }) {
  const [resources, setResources] = useState({
    holz: 50,
    stein: 50,
    nahrung: 50,
  });

  const [buildings, setBuildings] = useState({
    holzfaeller: { level: 1 },
    steinmetz: { level: 1 },
    farm: { level: 1 },
    lager: { level: 1 }, // Neues Gebäude: Lager
  });

  // Basis-Produktionsraten pro Minute bei Level 1
  const baseProductionRates = {
    holzfaeller: 50, // Geändert von 5 auf 50 für Testzwecke
    steinmetz: 30,
    farm: 40,
  };

  // Steigerungsfaktoren pro Level (Multiplikativ)
  const productionIncreaseFactors = {
    holzfaeller: 0.8, // 80% Steigerung pro Level
    steinmetz: 0.6,    // 60% Steigerung pro Level
    farm: 0.5,         // 50% Steigerung pro Level
  };

  // Berechnung der aktuellen Produktionsrate pro Sekunde (multiplikativ)
  const calculateProductionPerSecond = () => {
    const production = {
      holz: 0,
      stein: 0,
      nahrung: 0,
    };

    // Funktion zur multiplikativen Berechnung
    const calculateMultiplicativeProduction = (base, factor, level) => {
      let productionRate = base;
      for (let lvl = 2; lvl <= level; lvl++) {
        productionRate += productionRate * factor;
      }
      return productionRate;
    };

    // Holzproduktion durch Holzfäller
    const holzfaellerLevel = buildings.holzfaeller.level;
    const holzProMinute = calculateMultiplicativeProduction(
      baseProductionRates.holzfaeller,
      productionIncreaseFactors.holzfaeller,
      holzfaellerLevel
    );
    production.holz += holzProMinute / 60;

    // Steinproduktion durch Steinmetz (multiplikativ)
    const steinmetzLevel = buildings.steinmetz.level;
    const steinProMinute = calculateMultiplicativeProduction(
      baseProductionRates.steinmetz,
      productionIncreaseFactors.steinmetz,
      steinmetzLevel
    );
    production.stein += steinProMinute / 60;

    // Nahrungsproduktion durch Farm (multiplikativ)
    const farmLevel = buildings.farm.level;
    const nahrungProMinute = calculateMultiplicativeProduction(
      baseProductionRates.farm,
      productionIncreaseFactors.farm,
      farmLevel
    );
    production.nahrung += nahrungProMinute / 60;

    return production;
  };

  // Berechnung der Produktionsraten pro Minute für die Statistik (multiplikativ)
  const calculateProductionPerMinute = () => {
    const production = {
      holz: baseProductionRates.holzfaeller,
      stein: baseProductionRates.steinmetz,
      nahrung: baseProductionRates.farm,
    };

    // Funktion zur multiplikativen Berechnung
    const calculateMultiplicativeProduction = (base, factor, level) => {
      let productionRate = base;
      for (let lvl = 2; lvl <= level; lvl++) {
        productionRate += productionRate * factor;
      }
      return productionRate;
    };

    // Multiplikative Steigerung für Holzfäller
    production.holz = calculateMultiplicativeProduction(
      baseProductionRates.holzfaeller,
      productionIncreaseFactors.holzfaeller,
      buildings.holzfaeller.level
    );

    // Multiplikative Steigerung für Steinmetz
    production.stein = calculateMultiplicativeProduction(
      baseProductionRates.steinmetz,
      productionIncreaseFactors.steinmetz,
      buildings.steinmetz.level
    );

    // Multiplikative Steigerung für Farm
    production.nahrung = calculateMultiplicativeProduction(
      baseProductionRates.farm,
      productionIncreaseFactors.farm,
      buildings.farm.level
    );

    return production;
  };

  // Berechnung der Lagerkapazitäten basierend auf dem Level des Lagers
  const calculateStorageCapacity = () => {
    const baseCapacity = {
      holz: 200,
      stein: 100,
      nahrung: 200,
    };

    const multiplier = buildings.lager.level; // Lager-Level bestimmt den Multiplikator

    return {
      holz: baseCapacity.holz * multiplier,
      stein: baseCapacity.stein * multiplier,
      nahrung: baseCapacity.nahrung * multiplier,
    };
  };

  useEffect(() => {
    // Jede Sekunde die Ressourcen aktualisieren
    const interval = setInterval(() => {
      const productionPerSecond = calculateProductionPerSecond();
      const storageCapacity = calculateStorageCapacity();

      setResources((prevResources) => ({
        holz: Math.min(
          prevResources.holz + productionPerSecond.holz,
          storageCapacity.holz
        ),
        stein: Math.min(
          prevResources.stein + productionPerSecond.stein,
          storageCapacity.stein
        ),
        nahrung: Math.min(
          prevResources.nahrung + productionPerSecond.nahrung,
          storageCapacity.nahrung
        ),
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, [buildings]); // Aktualisieren, wenn sich die Gebäude ändern

  // Berechnung der Lagerkapazitäten für die Resource-Komponente
  const storageCapacity = calculateStorageCapacity();

  // Berechnung der Produktionsraten pro Minute für die Statistik
  const productionPerMinute = calculateProductionPerMinute();

  return (
    <div className="main-page">
      <div className="sidebar">
        <h2>Menü</h2>
        <ul>
          <li>
            <Link to="statistics">Statistiken</Link>
          </li>
          <li>
            <Link to="buildings">Gebäude</Link>
          </li>
        </ul>
      </div>
      <div className="content">
        <h1>Hallo, {userAddress}</h1>
        {/* showMax auf false setzen, um nur die aktuellen Ressourcen anzuzeigen */}
        <ResourceList resources={resources} storageCapacity={storageCapacity} showMax={false} />
        <Outlet
          context={{
            resources,
            buildings,
            setBuildings,
            setResources,
            storageCapacity,
            productionPerMinute, // Produktionsraten pro Minute weitergeben
          }}
        />
      </div>
    </div>
  );
}

export default MainPage;
