// src/pages/LoginPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function LoginPage({ onLogin }) {
  const navigate = useNavigate(); // Hook für die Navigation

  const handleLogin = async () => {
    if (window.ethereum) {
      try {
        // Verbindung mit MetaMask herstellen
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });

        // Überprüfen, ob die richtige Chain verwendet wird
        const iotaChainId = '0x433'; // Hexadezimal für 1075

        if (chainId !== iotaChainId) {
          try {
            // Versuchen, zur IOTA Chain zu wechseln
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: iotaChainId }],
            });
          } catch (switchError) {
            // Dieser Fehler tritt auf, wenn die Chain nicht hinzugefügt wurde
            if (switchError.code === 4902) {
              try {
                // Chain hinzufügen
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: iotaChainId,
                      chainName: 'IOTA EVM Testnet',
                      rpcUrls: ['https://json-rpc.evm.testnet.iotaledger.net'],
                      nativeCurrency: {
                        name: 'Testnet Token',
                        symbol: 'IOTA',
                        decimals: 18,
                      },
                      blockExplorerUrls: ['https://explorer.evm.testnet.iotaledger.net'],
                    },
                  ],
                });
                // Nach dem Hinzufügen zur neuen Chain wechseln
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: iotaChainId }],
                });
              } catch (addError) {
                console.error('Konnte die IOTA Chain nicht hinzufügen:', addError);
                return;
              }
            } else {
              console.error('Konnte nicht zur IOTA Chain wechseln:', switchError);
              return;
            }
          }
        }

        console.log('Logged in with address:', accounts[0]);
        onLogin(accounts[0]);
        navigate('/'); // Navigation zur MainPage nach erfolgreichem Login
      } catch (error) {
        console.error('MetaMask Login fehlgeschlagen:', error);
      }
    } else {
      alert('Bitte installieren Sie MetaMask!');
    }
  };

  return (
    <div>
      <h1>Willkommen zum Spiel</h1>
      <button onClick={handleLogin}>Mit MetaMask einloggen</button>
    </div>
  );
}

export default LoginPage;
